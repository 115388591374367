import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {useState} from "react";
import OfficerSearchRow from "./OfficerSearchRow";


function SearchBar(props) {
    return (
        <Container className="mt-5">
            <Row>
                <Col sm={6}>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Bob Smith"
                            className="me-2"
                            aria-label="Search"
                            onChange={(e) => props.setSearchTerm(e.target.value)}
                        />
                        <Button onClick={props.handleSearch}>
                            Search
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

function OfficerResults(props) {
    const results = props.results;
    {
        console.log(results)
    }

    if (results) {
        const listItems = results.map((officer) =>
            <OfficerSearchRow officer={officer} key={officer.id}/>
        );
        return (<ul>{listItems}</ul>);
    } else {
        return (<p>No results</p>)
    }
}


export default () => {
    let searchTerm = "";
    let lastSearchTerm = "";
    let searchTimeout = setTimeout(handleSearch, 0);

    const [searchResults, setSearchResults] = useState([]);

    function setSearchTerm(term) {
        clearTimeout(searchTimeout)
        searchTerm = term;
        searchTimeout = setTimeout(handleSearch, 1000)
    }


    function fetchSearchResults() {
        fetch("https://sa7npvvsea.execute-api.us-east-1.amazonaws.com/default/Lookup/officer/" + searchTerm)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setSearchResults(data);
            })
    }


    function handleSearch() {
        if ((searchTerm !== lastSearchTerm) && (searchTerm !== "")) {
            fetchSearchResults();
            lastSearchTerm = searchTerm;
        }
    }

    return (
        <div>
            <h3>Look up an Officer to see Reviews</h3>
            <p>Search an officer by name:</p>
            <SearchBar setSearchTerm={setSearchTerm} handleSearch={handleSearch}/>
            <OfficerResults results={searchResults}/>
        </div>
    );
}