import {Component} from "react";

export default () => {
    return (
        <div>
            <h1 className="brand-font">About Cop.Report</h1>
            <h5>Our Mission</h5>
            <p>
                At Cop.Report, we believe in fostering transparency, accountability, and community engagement within our
                law enforcement agencies. Our mission is to provide a platform where citizens can share their
                experiences, rate police officers based on their encounters, and promote a more trustworthy relationship
                between the police and the communities they serve.
            </p>
            <h5>Our Story</h5>
            <p>
                Cop.Report was founded in 2023 by a group of students and founders. The
                idea for Cop.Report was born out of the need for a platform where citizens can share their experiences
                with law enforcement officers. We believe that the police should be held accountable for their actions,
                and that the public should be able to access information about the officers that serve them.
            </p>
            <h5>Security & Privacy</h5>
            <p>
                We understand the sensitivity of the information shared on our platform. That's why all the data we
                collect is anonymized and encrypted. We also take your privacy very seriously especially considering the
                backlash that could be faced by those who report on police officers. We will never share your
                information with anyone.
            </p>
            <h5>Ethical Considerations</h5>
            <p>
                Cop.Report is built on the principles of respect, integrity, and social responsibility. We actively
                monitor
                the content to ensure it adheres to legal and ethical guidelines. Our aim is to contribute positively to
                society by providing a fair and balanced perspective on law enforcement officers' performance.
            </p>

            <h5>Get Involved</h5>
            <p>
                Your voice matters! Whether you're a citizen wanting to share an experience, a law enforcement
                professional interested in engaging with the community, or an advocate for police reform, we invite you
                to join us. Together, we can make a difference.
            </p>
            <h5>Contact Us</h5>
            <p>Have questions or feedback? Reach out to our support team at</p>
            <a href="mailto:support@cop.report"> support@cop.report</a>
            <p>and we'll be happy to assist you. </p>
            support@cop.report
            <h5>Disclaimer</h5>
            <p>
                Cop.Report is a public platform for sharing experiences and opinions related to police officers'
                conduct. The views and opinions expressed here are those of the authors and do not necessarily reflect
                the official policy or position of any police department or governmental agency. All content is provided
                "as is" without warranty of any kind.
            </p>
        </div>
    );
}