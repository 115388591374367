export default function Rating(props) {
    const rating = props.rating;
    const badges = Math.round(rating / .2);
    const pigs = 5 - badges;
    return (
        <div>
            {(rating * 5.0).toFixed(1)} {"👮".repeat(badges)}{"🐷".repeat(pigs)}
        </div>
    );
}

