import OfficerOfTheDay from "./OfficerOfTheDay";
import PigOfTheDay from "./PigOfTheDay";
import {Row} from "react-bootstrap";
import Hero from "./Hero";
import AdsComponent from "./AdsComponent";

export default () => {
    return (

        <div>
            <Hero/>
            <Row xs={1} md={2} className="g-4">
                <OfficerOfTheDay/>
                <PigOfTheDay/>
            </Row>
        </div>
    );
}