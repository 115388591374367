import Card from "react-bootstrap/Card";
import Rating from "./Rating";
import {ListGroup} from "react-bootstrap";
import ErrorBoundary from "./ErrorBoundary";
import {Link} from "react-router-dom";


export default (props) => {
    const review = props.review;
    // console.log("review", review)

    return (
        <Card style={{minWidth: '24rem', maxWidth: '36rem', margin: "1rem"}}>
            <Card.Body>
                <ErrorBoundary fallback={<div>Failed to load review</div>}>
                    <Card.Header>
                        <Card.Title>{review.title}</Card.Title>
                        <Card.Subtitle><Rating rating={review.rating}/> Submitted
                            by {review.author.name} on {review.date}</Card.Subtitle>
                    </Card.Header>
                    <Card.Text>{review.body}</Card.Text>
                    <hr/>
                    <Card.Subtitle className="mb-2 text-muted">Incident Details</Card.Subtitle>
                    <ListGroup horizontal>
                        <ListGroup.Item>
                            {review.videos.map(video_url => {
                                return (<div><a href={video_url}>Video</a></div>)
                            })}
                        </ListGroup.Item>
                        {review.images.map(image_url => {
                            return (<ListGroup.Item><Card.Img src={image_url}/></ListGroup.Item>)
                        })}
                    </ListGroup>
                    <Card.Img variant="top" src="" width={"50mv"}/>
                    <Card.Footer>
                        <Card.Subtitle className="mb-2 text-muted">Involved Officers</Card.Subtitle>
                        <ListGroup horizontal>
                            {review.officers.map(officer => {
                                return (<ListGroup.Item href={"/#/officer/" + officer.id}>{officer.name}</ListGroup.Item>)
                            })}
                        </ListGroup>
                    </Card.Footer>
                </ErrorBoundary>
            </Card.Body>
        </Card>

    );
}