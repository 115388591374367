import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import reportWebVitals from './reportWebVitals';
import BasicNavbar from "./Components/Navbar";
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Lookup from "./Components/Lookup";
import Report from "./Components/Report";
import OfficerProfile from "./Components/OfficerProfile";
import Department from "./Components/DepartmentProfile";
import Footer from "./Components/Footer";
import ErrorBoundary from "./Components/ErrorBoundary";
import UserProfile from "./Components/UserProfile";


ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Router basename="/">
            <div className="App">
                <ErrorBoundary fallback={<div>Something went wrong loading navbar</div>}>
                    <BasicNavbar/>
                </ErrorBoundary>

                <ErrorBoundary fallback={<div>Something went wrong in app</div>}>
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route path="/report" element={<Report/>}/>
                        <Route path="/lookup" element={<Lookup/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/user/:page" action={({ params }) => {}} element={<UserProfile/>}/>
                        <Route path="/officer/:officerId" action={({ params }) => {}} element={<OfficerProfile/>}/>
                        <Route path="/department/:departmentId" action={({ params }) => {}} element={<Department/>}/>
                    </Routes>
                </ErrorBoundary>
                <div className="adsbygoogle"></div>
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <Footer/>
                </ErrorBoundary>
            </div>
        </Router>
    </React.StrictMode>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
