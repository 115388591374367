import React, {useState} from "react";
import {Button, Dropdown, Form, ListGroup} from "react-bootstrap";
import {Input, List} from "@material-ui/core";
import Officer from "./Officer";
import OfficerProfile from "./OfficerProfile";
import OfficerSearchRow from "./OfficerSearchRow";


const SearchToggle = React.forwardRef((props, ref) => (<div>
    <Input
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
        }}
        placeholder={props.placeholder}
        id={props.id}
        value={props.value}
    >
        {props.children}
        &#x25bc;
    </Input>
    &#x25bc;
</div>));


const SearchMenu = React.forwardRef(({children, style, className, 'aria-labelledby': labeledBy, toggleId}, ref) => {
    const [value, setValue] = useState("");


    return (<div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
    >
        <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
            id="foobar"
        />
        <ul className="list-unstyled">
            {React.Children.toArray(children).filter((child) => !value || !child.props.children || child.props.children.toLowerCase().startsWith(value),)}
        </ul>
    </div>);
},);


function DropdownSelect(props) {
    const areas = props.areas;
    const area = props.area;

    function updateArea(event) {
        let areaId = event.target.getAttribute('areaId');
        props.setArea([areaId, event.target.text]);
    }

    function convertAreaList(areas) {
        if (!areas?.length) {
            return <Dropdown.Item eventKey="foobar">No results</Dropdown.Item>
        }
        return areas.map((area) =>
            area.id === "-div-" ? <Dropdown.Divider/> :
                <Dropdown.Item areaid={area.id} eventKey={area.id} onClick={updateArea}>{area.name}</Dropdown.Item>
        );
    }

    const listItems = convertAreaList(areas)

    return (<Dropdown>
        <Dropdown.Toggle as={SearchToggle} placeholder={props.placeholder} id={props.id} value={area}>
            Select State or Federal
        </Dropdown.Toggle>

        <Dropdown.Menu as={SearchMenu} toggleId={props.id}>
            {listItems}
        </Dropdown.Menu>
    </Dropdown>);
}


function SearchBar(props) {
    return (<div>
        <input
            autoFocus
            type='text'
            autoComplete='off'
            className='live-search-field'
            placeholder='Select officer'
            onChange={(e) => props.setSearchTerm(e.target.value)}
        />
        <Button onClick={props.handleSearch}>
            Search
        </Button>
    </div>)
}

export default () => {

    const [region, setRegion] = useState("");
    const [county, setCounty] = useState("");
    const [department, setDepartment] = useState("");
    const [officer, setOfficer] = useState("");
    const [officerName, setOfficerName] = useState("");

    const states = [{'id': 'FED', 'name': 'Federal'}, {'id': '-div-', 'name': '-div-'}, {
        'id': 'AL',
        'name': 'Alabama'
    }, {'id': 'AK', 'name': 'Alaska'}, {'id': 'AZ', 'name': 'Arizona'}, {'id': 'AR', 'name': 'Arkansas'}, {
        'id': 'AS',
        'name': 'American Samoa'
    }, {'id': 'CA', 'name': 'California'}, {'id': 'CO', 'name': 'Colorado'}, {
        'id': 'CT',
        'name': 'Connecticut'
    }, {'id': 'DE', 'name': 'Delaware'}, {'id': 'DC', 'name': 'District of Columbia'}, {
        'id': 'FL',
        'name': 'Florida'
    }, {'id': 'GA', 'name': 'Georgia'}, {'id': 'GU', 'name': 'Guam'}, {'id': 'HI', 'name': 'Hawaii'}, {
        'id': 'ID',
        'name': 'Idaho'
    }, {'id': 'IL', 'name': 'Illinois'}, {'id': 'IN', 'name': 'Indiana'}, {'id': 'IA', 'name': 'Iowa'}, {
        'id': 'KS',
        'name': 'Kansas'
    }, {'id': 'OH', 'name': 'Ohio'}, {'id': 'OK', 'name': 'Oklahoma'}, {'id': 'OR', 'name': 'Oregon'}, {
        'id': 'PA',
        'name': 'Pennsylvania'
    }, {'id': 'PR', 'name': 'Puerto Rico'}, {'id': 'RI', 'name': 'Rhode Island'}, {
        'id': 'SC',
        'name': 'South Carolina'
    }, {'id': 'SD', 'name': 'South Dakota'}, {'id': 'TN', 'name': 'Tennessee'}, {
        'id': 'TX',
        'name': 'Texas'
    }, {'id': 'TT', 'name': 'Trust Territories'}, {'id': 'UT', 'name': 'Utah'}, {
        'id': 'VT',
        'name': 'Vermont'
    }, {'id': 'VA', 'name': 'Virginia'}, {'id': 'VI', 'name': 'Virgin Islands'}, {
        'id': 'WA',
        'name': 'Washington'
    }, {'id': 'WV', 'name': 'West Virginia'}, {'id': 'WI', 'name': 'Wisconsin'}, {
        'id': 'WY',
        'name': 'Wyoming'
    }, {'id': 'KY', 'name': 'Kentucky'}, {'id': 'LA', 'name': 'Louisiana'}, {'id': 'ME', 'name': 'Maine'}, {
        'id': 'MD',
        'name': 'Maryland'
    }, {'id': 'MA', 'name': 'Massachusetts'}, {'id': 'MI', 'name': 'Michigan'}, {
        'id': 'MN',
        'name': 'Minnesota'
    }, {'id': 'MS', 'name': 'Mississippi'}, {'id': 'MO', 'name': 'Missouri'}, {
        'id': 'MT',
        'name': 'Montana'
    }, {'id': 'NE', 'name': 'Nebraska'}, {'id': 'NV', 'name': 'Nevada'}, {
        'id': 'NH',
        'name': 'New Hampshire'
    }, {'id': 'NJ', 'name': 'New Jersey'}, {'id': 'NM', 'name': 'New Mexico'}, {
        'id': 'NY',
        'name': 'New York'
    }, {'id': 'NC', 'name': 'North Carolina'}, {'id': 'ND', 'name': 'North Dakota'}, {
        'id': 'MP',
        'name': 'Northern Mariana Islands'
    }];

    const [counties, setCounties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [officers, setOfficers] = useState([]);

    function updateRegion(new_region) {
        let regionId = new_region[0]
        let regionName = new_region[1]
        console.log(regionId, regionName)
        setRegion(regionName)
        setCounty("")
        setDepartments([])
        setDepartment("")
        setOfficers([])
        setOfficerName("")
        fetch("https://sa7npvvsea.execute-api.us-east-1.amazonaws.com/default/Lookup/county/" + regionId).then(response => {
            return response.json()
        }).then(data => {
            setCounties(data);
        })
    }

    function updateCounty(new_county) {
        let countyId = new_county[0]
        let countyName = new_county[1]
        setCounty(countyName)
        setDepartment("")
        setOfficers([])
        setOfficerName("")
        fetch("https://sa7npvvsea.execute-api.us-east-1.amazonaws.com/default/Lookup/department/" + countyId).then(response => {
            return response.json()
        }).then(data => {
            setDepartments(data);
        })
    }

    function updateDepartment(new_department) {
        let departmentId = new_department[0]
        let departmentName = new_department[1]
        setDepartment(departmentName)
        setOfficers([])
        setOfficerName("")
        fetch("https://sa7npvvsea.execute-api.us-east-1.amazonaws.com/default/Lookup/officer/" + departmentId).then(response => {
            return response.json()
        }).then(data => {
            console.log(data)
            setOfficers(data);
        })
    }

    function updateOfficer(new_officer) {
        let officerId = new_officer[0]
        officers.map((officer) => {
            if (officer.id === officerId) {
                setOfficerName(officer.name)
                setOfficer(officer)
            }
        })
    }

    return (<div>
        <h3>Look up officers by department</h3>
        <ListGroup as="ol" numbered>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <DropdownSelect id="area-dropdown-region" setArea={updateRegion}
                                    placeholder="Select state or Federal" areas={states} area={region}/>
                </div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <DropdownSelect id="area-dropdown-county" setArea={updateCounty}
                                    placeholder="Select county or city"
                                    areas={counties} area={county}/>
                </div>

            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <DropdownSelect id="area-dropdown-department" setArea={updateDepartment}
                                    placeholder="Select a department"
                                    areas={departments} area={department}/>
                </div>

            </ListGroup.Item>
        </ListGroup>
        {officers &&
            <ul>
                {officers.map((officer) =>
                    <OfficerSearchRow officer={officer} key={officer.id}/>
                )}
            </ul>
        }
        TODO fix this and add IDs to searches.
    </div>);
}