import React, {useEffect, useState} from "react"
import Officer from "./Officer";
import Rating from "./Rating";

export default function PigOfTheDay() {
    const [officer, setOfficer] = useState([])
    const fetchOfficerOfTheDay = () => {
        fetch("https://sa7npvvsea.execute-api.us-east-1.amazonaws.com/default/OfTheDay/pig")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setOfficer(data)
            })
    }

    useEffect(() => {
        fetchOfficerOfTheDay();
    }, [])


    return (
        <div>
            <h2>Pig of the Day</h2>
            <Officer officer={officer}/>
        </div>
    )
}