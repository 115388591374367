import React, {useState} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OfficerLookup from "./OfficerLookup";
import OrganizationLookup from "./DepartmentLookup";


export default () => {
    return (
        <div>
            <Tabs defaultActiveKey="Department"
                  id="lookup-tabs"
                  className="mb-3">
                <Tab eventKey="Officer" title="By Officer">
                    <OfficerLookup/>
                </Tab>
                <Tab eventKey="Department" title="By Department">
                    <OrganizationLookup/>
                </Tab>
            </Tabs>
        </div>
    );
}