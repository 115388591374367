import {useParams} from "react-router-dom";

export default (props) => {
    const user = props.user;

    let params = useParams();

    return (
        <div>
            <h1> User </h1>
            <p> {params.page} </p>
        </div>

    );
}