import Card from "react-bootstrap/Card";
import ErrorBoundary from "./ErrorBoundary";
import {Link} from "@material-ui/core";
import Rating from "./Rating";
import {Col, Image, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";

export default (props) => {
    const officer = props.officer;

    return (
        <Card style={{margin: "1rem"}}>
            <ErrorBoundary fallback={<div>Failed to load officer</div>}>
                <Card.Header>
                    <Container>
                        <Row>
                            <Col xs lg={3}>
                                <Link href={"/#/officer/" + officer.id}>
                                    <Card.Title>{officer.name}</Card.Title>
                                </Link>
                                <Card.Text as="div"><Rating rating={officer.rating}/></Card.Text>
                                <Link href={"/#/organization/" + officer.organization.id}>
                                    <Card.Text>{officer.organization.name}</Card.Text>
                                </Link>
                            </Col>
                            <Col>
                                <Image src={officer.profile_url} style={{height: "100px"}} thumbnail/>
                            </Col>
                        </Row>
                    </Container>
                </Card.Header>
            </ErrorBoundary>
        </Card>
    );
}