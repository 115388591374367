import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {NavLink} from "react-router-dom";

export default function BasicNavbar() {
    return (
        <Navbar className="bg-body-tertiary">
            <Container>
                <Navbar.Brand className="navbar-brand" href="/">Cop Report</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" defaultActiveKey="/">
                        <Nav.Link as={NavLink} activeClassName="active" to="/">Home</Nav.Link>
                        <Nav.Link as={NavLink} activeClassName="active" to="/report">Report</Nav.Link>
                        <Nav.Link as={NavLink} activeClassName="active" to="/lookup">Lookup</Nav.Link>
                        <Nav.Link as={NavLink} activeClassName="active" to="/about">About</Nav.Link>
                        <NavDropdown title="User" id="basic-nav-dropdown">
                            <NavDropdown.Item as={NavLink} eventKey="userProfile"
                                              to="/user/profile">Profile</NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} eventKey="userReviews"
                                              to="/user/reviews">Reviews</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item as={NavLink} eventKey="userLogout"
                                              to="/user/logout"> Logout </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}