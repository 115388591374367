import React, {useEffect, useState} from "react";
import Officer from "./Officer";
import ReviewList from "./ReviewList";
import {Col, Row} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import {Link} from "@material-ui/core";
import {useParams} from "react-router-dom";


export default function Profile(props) {
    const [department, setDepartment] = useState([])

    let params = useParams();
    const fetchOfficer = () => {
        fetch("https://sa7npvvsea.execute-api.us-east-1.amazonaws.com/default/Department/" + params.departmentId)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDepartment(data)
            })
    }

    useEffect(() => {
        fetchOfficer();
    }, [])


    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col xs lg={2}>
                        <Card.Title>{department.name}</Card.Title>
                    </Col>
                    <Col>
                        <Card.Text>{department.location}</Card.Text>
                        <Link href={department.url}>Homepage</Link>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                {/*TODO add officers here*/}
            </Card.Body>
        </Card>
    )
}