import Card from "react-bootstrap/Card";
import {Link} from "@material-ui/core";
import Rating from "./Rating";
import ErrorBoundary from "./ErrorBoundary";

function DepartmentLink(props) {
    const department = props.department;
    if (!department) {
        return
    }
    return (
        <Link href={"/#/department/" + department.id}>
            <Card.Text>{department.name}</Card.Text>
        </Link>
    );
}

export default function Officer(props) {
    const officer = props.officer;

    return (
        <Card style={{minWidth: '18rem', maxWidth: '24rem', margin: "1rem"}}>
            <Card.Body>
                <ErrorBoundary fallback={<div>Failed to load officer</div>}>
                    <Card.Header>
                        <Link href={"/#/officer/" + officer.id}>
                            <Card.Title>{officer.name}</Card.Title>
                        </Link>
                        <Card.Text as="div"><Rating rating={officer.rating}/> </Card.Text>
                        <DepartmentLink department={officer.organization}/>
                    </Card.Header>
                    <Card.Img variant="bottom" src={officer.profile_url} width={"50mv"}/>
                </ErrorBoundary>
            </Card.Body>
        </Card>
    );
}


