import {Component, useEffect, useState} from "react";
import Review from "./Review";

export default function ReviewList(props) {
    const officerId = props.officerId
    const [reviews, setReviews] = useState([])
    const fetchReviews = () => {
        fetch("https://sa7npvvsea.execute-api.us-east-1.amazonaws.com/default/Review/" + officerId)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setReviews(data)
            })
    }

    useEffect(() => {
        if (officerId) {
            fetchReviews();
        }
    }, [])

    return (

        <div className="review-list section">
            {reviews &&
                reviews.map(review => {
                    return (
                        <Review review={review} key={review.id}/>
                    );
                })}
        </div>
    );
}

