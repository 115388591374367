import React, {Component, useEffect, useState} from "react";
import Officer from "./Officer";
import ReviewList from "./ReviewList";
import {Col, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";


export default function OfficerProfile(props) {
    const [officer, setOfficer] = useState([])

    let params = useParams();
    const fetchOfficer = () => {
        fetch("https://sa7npvvsea.execute-api.us-east-1.amazonaws.com/default/Officer/" + params.officerId)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setOfficer(data)
            })
    }

    useEffect(() => {
        fetchOfficer();
    }, [])


    return (
        <div>
            <Row xs={1} md={2} className="g-4">
                {officer.id &&
                    <Officer officer={officer}/>
                }
                {/*<Card>Ad Here</Card>*/}
                {/*TODO fix Ads*/}
                {officer.id &&
                    <ReviewList officerId={officer.id}/>
                }
            </Row>
        </div>
    )
}