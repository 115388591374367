import hero_image from '../static/hero.webp';

export default function Hero() {
    return (
        <header style={{paddingLeft: 0}}>
            <div className='p-5 text-center bg-image'
                 style={{backgroundImage: `url(${hero_image})`, height: 400}}>

                <div className='mask text-center' style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-white'>
                            <h1 className='mb-3 brand-font'>Cop Report</h1>
                            <h4 className='mb-3'>Look up an officer, or add your own report</h4>
                            <a className='btn btn-outline-light btn-lg' href='#/lookup' role='button'>
                                Lookup
                            </a>
                            <a className='btn btn-outline-light btn-lg' href='#/report' role='button'>
                                Report
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}